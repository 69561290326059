<template>
  <v-container class="pt-7 pb-11">
    <h1 class="text-h4 mb-5">{{ 'clients'|localize }}</h1>
    <div class="white elevation-2 rounded">
      <v-row class="ma-0 mb-1">
        <v-col cols="4">
          <v-text-field
            :background-color="search ? 'serenade' : ''"
            v-model.trim="search"
            prepend-inner-icon="mdi-magnify"
            outlined
            hide-details
            dense
            clearable
            :placeholder="'search'|localize"
          />
        </v-col>
        <v-spacer/>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="clients"
        :loading="loading"
        loader-height="2"
        :sort-by="defaultSortBy"
        :sort-desc="defaultSortDesc"
        :options.sync="options"
        :server-items-length="totalItems"
        :no-data-text="'noDataAvailable'|localize"
        hide-default-footer
      >
        <template v-slot:item.settings="{ item }">
          <v-btn :disabled="!!loginUserId" color="light-green lighten-4" :loading="loginUserId === item.id" depressed small rounded @click="loginAsUser(item)">
            {{ 'loginAs'|localize }}
            <template v-slot:loader>
              <v-progress-circular indeterminate :width="2" :size="15"/>
            </template>
          </v-btn>
        </template>
      </v-data-table>
      <v-pagination
        color="primary"
        v-model="currentPage"
        :length="totalPages"
        :total-visible="7"
        class="py-6"
        :disabled="loading"
        @input="clients = [];getClients();"
      />
    </div>
  </v-container>
</template>

<script>
  import api from '@/api/api';
  import localize from '@/filters/localize';
  import methods from '@/helpers/methods';

  export default {
    name: 'Clients',

    data() {
      return {
        loading: true,
        defaultSortBy: '',
        defaultSortDesc: false,
        debouncedSearch: '',
        debounceTimeout: null,
        options: {},
        itemsPerPage: 20,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        clients: [],
        loginUserId: null
      };
    },

    watch: {
      options: {
        handler() {
          this.clearClients();
          this.getClients();
        },
        deep: true,
      },
      search() {
        this.clearClients();
        this.getClients();
      },
    },

    computed: {
      headers() {
        return [
          {text: localize('id'), value: 'id', sortable: false},
          {text: localize('name'), value: 'name', sortable: true},
          {text: localize('email'), value: 'email', sortable: false},
          {text: '', value: 'settings', sortable: false, width: 50, align: 'center'},
        ];
      },
      search: {
        get() {
          return this.debouncedSearch
        },
        set(val) {
          if (this.debounceTimeout) clearTimeout(this.debounceTimeout)
          this.debounceTimeout = setTimeout(() => {
            this.debouncedSearch = val
          }, 500)
        }
      },
    },

    methods: {
      clearClients() {
        this.clients = [];
        this.totalPages = 0;
        this.totalItems = 0;
        this.currentPage = 1;
      },
      async loginAsUser(user) {
        try {
          this.loginUserId = user['id'];
          await api.logout();
          methods.clearUserData();
          window.location.href = `${window.location.origin}${user['login']}`;
        } catch (e) {
          this.loginUserId = null;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      },
      setClientsData(companiesData) {
        this.clients = companiesData.data.data;
        this.totalPages = companiesData.data.pagination['total_page'];
        this.totalItems = companiesData.data.pagination['total_items'];
      },
      queryData() {
        let queryData = {};
        queryData['per_page'] = this.itemsPerPage;
        queryData['page'] = this.currentPage;
        this.options.sortBy[0] && (
          queryData['sort'] = {
            field: this.options.sortBy[0],
            desc: this.options.sortDesc[0],
          });
        this.search && (queryData['search'] = this.search);
        return queryData;
      },
      async getClients () {
        try {
          this.loading = true;
          let companiesData = await api.getClients(this.queryData());
          this.setClientsData(companiesData);
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      }
    }
  };
</script>
